.Modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  overflow: auto;
  transition: .2s;

  z-index: 30;

  opacity: 0;
  visibility: hidden;

  &.is-active {
    opacity: 1;
    visibility: visible; }

  &.small {
    .Modal-header {
      padding-bottom: 1.5rem; }

    .Modal-title {
      font-size: 1.8rem; }

    .Modal-close {
      width: 1.8rem; }

    .Modal-box {
      max-width: 40rem; } } }

.Modal {
  .Modal-box {
    transition: .2s;
    transform: translate3d(0, -3rem, 0); } }

.Modal.is-active {
  .Modal-box {
    transform: translate3d(0, 0, 0); } }

.Modal-bg {
  background-color: rgba(#000, .4);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 31; }

.Modal-box {
  background-color: #fff;
  position: relative;
  z-index: 32;

  padding: 0.5rem 3.5rem 0.5rem 3.5rem;
  margin: auto;
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(#000, .2);

  max-width: 10rem;
  width: 60%; }

.Modal-header {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  //+media(700px)
 }  //  padding-bottom: 2rem

.Modal-title {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.2;
  color: #c63f43;

  //+media(700px)
 }  //  font-size: 2.4rem

.Modal-close {
  cursor: pointer;
  width: 1.8rem;
  color: #c63f43;

  //+media(700px)
 }  //  width: 2rem
